import { MouseEvent } from 'react';
import { getCookie } from 'cookies-next';

type IHandleGTMClick = (event: MouseEvent, callback?: () => unknown, forceId?: string) => unknown;

// eslint-disable-next-line @typescript-eslint/no-empty-function, default-param-last
export const handleGTMClick: IHandleGTMClick = (event: MouseEvent, callback = () => {}, forceId) => {
  const hasAccepted = getCookie('acceptCookies');
  const buttonId = event.currentTarget.id;
  const hasId = forceId || buttonId;

  if (!hasId || !hasAccepted) return callback();

  const clickEvent = {
    event: 'clique_botao',
    botao_id: forceId || buttonId,
    pagina: window.location.pathname,
  };

  // @ts-ignore
  window.dataLayer?.push(clickEvent);

  callback();
};
