import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button } from '@chakra-ui/react';
import Image from 'next/image';
import { configFile } from '@/config';

interface Props {
  bottom?: boolean;
  right?: boolean;
  big?: boolean;
  medium?: boolean;
  logo?: boolean;
  search?: boolean;
}

export default function GradientButton({ bottom, right, big, medium, logo, search }: Props) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
      width="100%"
      height="100%"
      zIndex="9"
    >
      <Box
        position="relative"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={big ? '112px' : medium ? '88px' : '78px'}
        height={big ? '112px' : medium ? '88px' : '78px'}
        borderRadius="9999px"
        bg="rgba(49, 151, 149, 0.06)"
        zIndex="1"
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bg="rgba(49, 151, 149, 0.2)"
        borderRadius="9999px"
        width={big ? '81px' : medium ? '64px' : '56px'}
        height={big ? '81px' : medium ? '64px' : '56px'}
        zIndex="2"
      >
        <Button
          aria-label="Gradient Button"
          bg={configFile.fourthAccentColor}
          _hover={{ bg: configFile.fourthAccentColor }}
          borderRadius="99999999px"
          width={big ? '46px' : medium ? '40px' : '32px'}
          height={big ? '46px' : medium ? '40px' : '32px'}
          style={{ aspectRatio: '1/1' }}
          minWidth="0"
          zIndex="3"
          cursor="default"
        >
          {bottom && (
            <ChevronDownIcon
              color={configFile.tertiaryAccentColor}
              fontSize={big ? '24px' : medium ? '20px' : '16px'}
            />
          )}
          {right && (
            <ChevronRightIcon
              color={configFile.tertiaryAccentColor}
              fontSize={big ? '24px' : medium ? '20px' : '16px'}
            />
          )}
          {logo && (
            <Image
              src="/images/default/icon-logo-white.svg"
              alt="Logo"
              width="21"
              height="21"
              style={{ position: 'absolute', zIndex: '4' }}
            />
          )}
          {search && (
            <Image
              src="/images/default/search.svg"
              alt="Search"
              width="24"
              height="24"
              style={{ position: 'absolute', zIndex: '4' }}
            />
          )}
        </Button>
      </Box>
    </Box>
  );
}
