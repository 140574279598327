import React, { useEffect, useState } from 'react';
import { Flex, Box, IconButton, useDisclosure, Link as ChakraLink, Container, Text, Button } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { hasCookie, setCookie } from 'cookies-next';
// eslint-disable-next-line import/no-extraneous-dependencies
import TagManager from 'react-gtm-module';

import 'instantsearch.css/themes/reset.css';
import 'instantsearch.css/themes/satellite.css';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { HeaderMenuData } from '@/Types/types';
import { configFile } from '@/config';
import { handleGTMClick } from '@/helpers/handleGTMClick';

interface Props {
  menu: HeaderMenuData[];
  cookies: any;
  submenu: any;
}

const ONE_YEAR_IN_SECONDS = 60 * 60 * 24 * 365;
const ONE_WEEK_IN_SECONDS = 60 * 60 * 24 * 7;

const DesktopNav = dynamic(() => import('@/components/DesktopNav'));

export default function HeaderMenu({ menu, cookies, submenu }: Props) {
  const { isOpen, onToggle } = useDisclosure();
  const [isCookieBanner, setIsCookieBanner] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<Boolean>();
  const { t } = useTranslation('header');
  const router = useRouter();

  const MobileNav = dynamic(() => import('@/components/MobileNav'), {
    ssr: false,
  });
  const DesktopNavSubmenu = dynamic(() => import('@/components/DesktopNavSubmenu'), {
    ssr: false,
  });

  useEffect(() => {
    if (!hasCookie('acceptCookies') || !hasCookie('notFirstVisit')) {
      setIsCookieBanner(true);
    } else {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER! });

      const cookiesData = async () => {
        cookies.forEach((item: any) => {
          if (!hasCookie(`${item.attributes.name}`)) {
            const maxAge = () => {
              if (
                item.attributes.duration.days * item.attributes.duration.hours * item.attributes.duration.minutes ===
                0
              ) {
                return new Date(new Date().setFullYear(new Date().getFullYear() + 1)).getTime();
              }

              return item.attributes.duration.days * item.attributes.duration.hours * item.attributes.duration.minutes;
            };

            setCookie(`${item.attributes.name}`, `${item.attributes.description}`, {
              path: '/',
              maxAge: maxAge(),
              sameSite: 'strict',
            });
          }
        });
      };

      cookiesData();
    }

    setIsMobile(window.innerWidth <= 830);
  }, []);

  const closeCookieBanner = ({ target }: { target: any }) => {
    setIsCookieBanner(false);

    setCookie('notFirstVisit', true, {
      path: '/',
      maxAge: ONE_WEEK_IN_SECONDS,
    });

    if (target.value === 'true') {
      setCookie('acceptCookies', true, {
        path: '/',
        maxAge: ONE_YEAR_IN_SECONDS,
      });

      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER! });
    }
  };

  return (
    <>
      {isCookieBanner && (
        <Container as="section" pb="10px" zIndex="999" position="fixed" bottom="0" minWidth="100%">
          <Box
            bg="blue.700"
            color="#fff"
            p="24px 16px"
            position="relative"
            borderRadius="10px"
            border="1px solid rgba(255, 255, 255, 0.18)"
            margin="0 auto"
            maxW="1216px"
            width={{ lg: '95%' }}
            boxShadow="0px 8px 32px -6px rgba(31, 38, 135, 0.37)"
            backdropFilter="blur(3.75px)"
          >
            <Flex
              direction={{ base: 'column', md: 'row' }}
              justifyContent="stretch"
              alignItems="center"
              gap={['12px', '80px']}
            >
              <Flex gap="12px" flex={{ base: 'column', lg: 'row' }}>
                <Box position="relative" boxSize="25px" alignSelf="center">
                  <Image
                    alt="cookie simbol"
                    src="/images/cookies.png"
                    fill
                    style={{ alignSelf: 'center', objectFit: 'cover' }}
                  />
                </Box>
                <Text flex="1 0 0" color="#F9F9F9" fontSize="14px" fontWeight="400" lineHeight="16px">
                  {t('cookies')}{' '}
                  <ChakraLink textDecor="underline" as={Link} href="/politica-de-privacidade">
                    {t('privacy')}{' '}
                  </ChakraLink>
                </Text>
              </Flex>
              <Flex gap="12px">
                <Button
                  aria-label="deny cookies"
                  colorScheme="white"
                  fontSize="sm"
                  lineHeight="5"
                  fontWeight="normal"
                  value="false"
                  onClick={closeCookieBanner}
                >
                  {t('deny')}
                </Button>
                <Button
                  aria-label="accept cookies"
                  colorScheme="whiteAlpha"
                  fontSize="sm"
                  lineHeight="5"
                  fontWeight="semibold"
                  bg="white"
                  color="blue.900"
                  p="10px 24px"
                  onClick={closeCookieBanner}
                  value="true"
                >
                  {t('accept')}
                </Button>
              </Flex>
            </Flex>
          </Box>
        </Container>
      )}
      <Box
        className="nav-wrapper"
        zIndex="999"
        position="fixed"
        backgroundColor={configFile.primaryAccentColor}
        top="0px"
        width="100%"
        height="96px"
      >
        <Flex
          maxW="1216px"
          width="95%"
          m="0 auto"
          pt={{ base: '24px', lg: '24px' }}
          pb={{ base: '24px', lg: '24px' }}
          pl={{ base: '0px', lg: '0' }}
          align="center"
          justifyContent="space-between"
        >
          <Flex align="center" columnGap="84px">
            <Link id="header_index" onClick={handleGTMClick} onAuxClick={handleGTMClick} href="/">
              <Image
                priority
                src="/images/default/logo-white.svg"
                alt="Ecto"
                width="96"
                height="28"
                placeholder="blur"
                style={{ width: 'auto', height: 'auto' }}
                blurDataURL="/images/default/logo-white.svg"
              />
            </Link>
          </Flex>
          {menu &&
            (isMobile ? <MobileNav isOpen={isOpen} menu={menu} submenu={submenu} /> : <DesktopNav menu={menu} />)}
          <IconButton
            onClick={onToggle}
            display={{ lg: 'none' }}
            icon={
              isOpen ? (
                <CloseIcon w={3} h={3} color={configFile.tertiaryAccentColor} />
              ) : (
                <HamburgerIcon w={5} h={5} color={configFile.tertiaryAccentColor} />
              )
            }
            variant="ghost"
            aria-label="Toggle Navigation"
          />
        </Flex>
        {router.pathname.includes('/blog') && (
          <Box w="100%" bgColor={configFile.primaryAccentColor}>
            <DesktopNavSubmenu submenu={submenu} />
          </Box>
        )}
      </Box>
    </>
  );
}
