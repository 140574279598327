export const configFile = {
  primaryAccentColor: '#0A1730',
  secondaryAccentColor: '#122345',
  tertiaryAccentColor: '#FFFFFF',
  fourthAccentColor: '#319795',
  fiveAccentColor: '#38B2AC',
  sixAccentColor: '#1A365D',
  sevenAccentColor: '#2C5282',
  eightAccentColor: '#2B6CB0',
  nineAccentColor: '#F1F6FF',
  tenAccentColor: '#5B6BB6',
  elevenAccentColor: '#203669',
};
