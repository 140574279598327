import { Box } from '@chakra-ui/react';

interface Props {
  small?: boolean;
}

export default function GradientBackground({ small }: Props) {
  return (
    <Box
      position="relative"
      left="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      w={small ? '307px' : '899px'}
      h={small ? '307px' : '899px'}
      borderRadius="9999px"
      bg="rgba(49, 151, 149, 0.06)"
      zIndex="1"
    >
      <Box
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
        w={small ? '220px' : '645px'}
        h={small ? '220px' : '645px'}
        borderRadius="9999px"
        bg="rgba(49, 151, 149, 0.2)"
        zIndex="2"
      >
        <Box
          position="absolute"
          w={small ? '126px' : '368px'}
          h={small ? '126px' : '368px'}
          borderRadius="9999px"
          bg="rgba(49, 151, 149, 0.06)"
          zIndex="3"
        />
      </Box>
    </Box>
  );
}
