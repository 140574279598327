import {
  Flex,
  Box,
  Link as ChakraLink,
  Text,
  Stack,
  IconButton,
  Heading,
  Button,
  useMediaQuery,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import Link from 'next/link';
import Image from 'next/image';

import { AiFillLinkedin, AiFillFacebook, AiOutlineInstagram, AiOutlineTikTok, AiFillYoutube } from 'react-icons/ai';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { FooterClients, FooterMenuData, FooterSubMenu } from '@/Types/types';

import GradientButton from '@/components/GradientButton';
import GradientBackground from '@/components/GradientBackground';

import { configFile } from '@/config';
import { handleGTMClick } from '@/helpers/handleGTMClick';

interface Props {
  menu: FooterMenuData[];
  clients: FooterClients[];
}

interface PathButtonIds {
  [key: string]: string;
}

const ITEM_PATH_TO_ID: PathButtonIds = {
  '/': 'footer_index',
  '/sobre': 'footer_about',
  '/ferramentas': 'footer_tools',
  '/blog': 'footer_blog',
  '/contato': 'footer_contact',
  '/cenarios/seo-para-startups': 'footer_startups',
  '/cenarios/seo-para-lojas-virtuais': 'footer_ecommerce',
  '/cenarios/migracao-de-sites': 'footer_migration',
  '/cenarios/auditoria-de-seo': 'footer_audit',
  '/cenarios/seo-para-blogs': 'footer_blogs',
  '/servicos/consultoria-em-seo': 'footer_seo',
  '/servicos/local-seo': 'footer_local_seo',
  '/servicos/conteudo': 'footer_content',
  '/servicos/digital-analytics': 'footer_analytics',
  '/politica-de-privacidade': 'footer_privacy',
  'https://locasty.com/': 'footer_site_locasty',
  'https://seodex.io/': 'footer_site_seodex',
};

export default function Footer({ menu, clients }: Props) {
  const [isMobileScreen] = useMediaQuery('(max-width: 991px)');
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const { t } = useTranslation('common');
  const router = useRouter();

  const toggleAccordion = (index: number) => {
    if (expandedIndex === index) {
      setExpandedIndex(null);
    } else {
      setExpandedIndex(index);
    }
  };

  const handleClickTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Flex direction="column">
      {router.query.slug !== 'contato' && (
        <Box as="footer" position="relative" bgColor={configFile.secondaryAccentColor} w="100vw" overflow="hidden">
          <Flex position="absolute" left={{ base: '-855px', lg: '-600px' }} top={{ base: '-120px', lg: '-100px' }}>
            <GradientBackground />
          </Flex>
          <Flex
            direction="column"
            w="100%"
            m={{ base: '48px auto', lg: '112px auto' }}
            maxW="1216px"
            width="100%"
            alignItems="center"
            gap={{ base: '32px', lg: '72px' }}
          >
            <Flex direction="column" gap="16px" alignItems="center">
              <Text
                as="span"
                color={configFile.tertiaryAccentColor}
                fontSize={{ base: '36px', lg: '48px' }}
                width={{ base: '375px', lg: '633px' }}
                textAlign="center"
                lineHeight={{ base: '40px', lg: '48px' }}
                fontWeight={800}
              >
                {t('footer.title')}
              </Text>
              <Text
                as="span"
                color={configFile.tertiaryAccentColor}
                fontSize="18px"
                fontWeight="400"
                lineHeight="28px"
                width={{ base: '375px', lg: '533px' }}
                textAlign="center"
              >
                {t('footer.text')}
              </Text>
            </Flex>
            <Flex direction="column" alignItems="center" gap="41px">
              <Text
                as="span"
                fontWeight={700}
                fontSize="24px"
                lineHeight="32px"
                textAlign="center"
                color="teal.300"
                width={{ base: '325px', lg: '633px' }}
              >
                {t('footer.companies')}
              </Text>
              {!isMobileScreen && (
                <Flex direction="row" alignItems="center" gap="72px">
                  {clients.map((client, i) => {
                    return (
                      <Image
                        key={i}
                        src={client.attributes.logo.data.attributes.url}
                        alt={client.attributes.logo.data.attributes.alternativeText}
                        style={{ filter: 'invert(100%) grayscale(100%)' }}
                        width={client.attributes.logo.data.attributes.width}
                        height={client.attributes.logo.data.attributes.height}
                      />
                    );
                  })}
                </Flex>
              )}
              {isMobileScreen && (
                <Flex w="90%" position="relative" m="0 auto">
                  <Box
                    position="absolute"
                    transform="rotate(180deg)"
                    right="0"
                    top="10%"
                    width="136px"
                    height="40px"
                    style={{ pointerEvents: 'none' }}
                    background="linear-gradient(90deg, #122345 21.69%, rgba(18, 35, 69, 0) 75.3%)"
                    zIndex="2"
                  />
                  <Flex
                    direction="row"
                    alignItems="center"
                    gap={{ base: '36px', lg: '72px' }}
                    w="90%"
                    m="0 auto"
                    p="0 50px"
                    overflowX="scroll"
                    position="relative"
                    css={{
                      '&::-webkit-scrollbar': {
                        display: 'none',
                      },
                      scrollbarWidth: 'none',
                      msOverflowStyle: 'none',
                    }}
                  >
                    {clients.map((client, i) => {
                      return (
                        <Image
                          key={i}
                          src={client.attributes.logo.data.attributes.url}
                          alt={client.attributes.logo.data.attributes.alternativeText}
                          width={client.attributes.logo.data.attributes.width}
                          height={client.attributes.logo.data.attributes.height}
                          style={{ filter: 'invert(100%) grayscale(100%)' }}
                        />
                      );
                    })}
                  </Flex>
                </Flex>
              )}
            </Flex>
            <Button
              id="footer_button_contact"
              aria-label="contact page"
              size="lg"
              bgColor={configFile.fourthAccentColor}
              color={configFile.tertiaryAccentColor}
              _hover={{ bgColor: '#38B2AC' }}
              onClick={(e) => handleGTMClick(e, () => router.push('/contato'))}
            >
              {t('footer.button')}
            </Button>
          </Flex>
        </Box>
      )}
      {router.query.slug !== 'contato' && (
        <Flex direction="column" alignItems="center" position="relative" w="100%" h="0px">
          <GradientButton bottom />
        </Flex>
      )}
      <Box as="footer" backgroundColor={configFile.primaryAccentColor} top="0px" width="100%" position="relative">
        <Flex maxW="1216px" width="95%" m="0 auto" direction="column">
          <Flex
            maxW="1216px"
            width={{ base: '95%', lg: '100%' }}
            m="0 auto"
            flexDirection={{ base: 'column', lg: 'row' }}
            pt={{ base: '48px', lg: '80px' }}
            pb={{ base: '32px', lg: '108px' }}
            align="flex-start"
            justifyContent={{ base: 'flex-start', lg: 'space-between' }}
          >
            <Box mt={{ base: '0px', lg: '0' }}>
              <ChakraLink id="footer_index" onClick={handleGTMClick} onAuxClick={handleGTMClick} as={Link} href="/">
                <Image src="/images/default/logo-white.svg" alt="Ecto" width={121} height={28} />
              </ChakraLink>
              <Text
                as="p"
                mt="20px"
                fontSize="16px"
                color={configFile.tertiaryAccentColor}
                lineHeight="24px"
                w={{ base: '100%', lg: '385px' }}
              >
                {t('footer.text2')}
              </Text>
              <Flex mt="30px" mb={{ base: '32px', lg: '0' }} gap="16px">
                <ChakraLink
                  id="footer_linkedin"
                  onClick={handleGTMClick}
                  onAuxClick={handleGTMClick}
                  as={Link}
                  href="https://www.linkedin.com/company/ecto-digital/"
                  target="_blank"
                  rel="noopener noreferrer"
                  padding="0 !important"
                  width="32px"
                >
                  <IconButton
                    aria-label="linkedin"
                    icon={<AiFillLinkedin color={configFile.tertiaryAccentColor} width="12px" />}
                    bgColor={configFile.secondaryAccentColor}
                    size="sm"
                  />
                </ChakraLink>
                <ChakraLink
                  id="footer_facebook"
                  onClick={handleGTMClick}
                  onAuxClick={handleGTMClick}
                  as={Link}
                  href="https://www.facebook.com/ecto.digital/"
                  target="_blank"
                  rel="noopener noreferrer"
                  padding="0 !important"
                  width="32px"
                >
                  <IconButton
                    aria-label="facebook"
                    icon={<AiFillFacebook color={configFile.tertiaryAccentColor} width="12px" />}
                    bgColor={configFile.secondaryAccentColor}
                    size="sm"
                  />
                </ChakraLink>
                <ChakraLink
                  id="footer_instagram"
                  onClick={handleGTMClick}
                  onAuxClick={handleGTMClick}
                  as={Link}
                  href="https://www.instagram.com/ecto.digital/"
                  target="_blank"
                  rel="noopener noreferrer"
                  padding="0 !important"
                  width="32px"
                >
                  <IconButton
                    aria-label="instagram"
                    icon={<AiOutlineInstagram color={configFile.tertiaryAccentColor} width="12px" />}
                    bgColor={configFile.secondaryAccentColor}
                    size="sm"
                  />
                </ChakraLink>
                <ChakraLink
                  id="footer_youtube"
                  onClick={handleGTMClick}
                  onAuxClick={handleGTMClick}
                  as={Link}
                  href="https://www.youtube.com/@ecto-digital"
                  target="_blank"
                  rel="noopener noreferrer"
                  padding="0 !important"
                  width="32px"
                >
                  <IconButton
                    aria-label="youtube"
                    icon={<AiFillYoutube color={configFile.tertiaryAccentColor} width="12px" />}
                    bgColor={configFile.secondaryAccentColor}
                    size="sm"
                  />
                </ChakraLink>
                <ChakraLink
                  id="footer_tiktok"
                  onClick={handleGTMClick}
                  onAuxClick={handleGTMClick}
                  as={Link}
                  href="https://www.tiktok.com/@ecto.digital"
                  target="_blank"
                  rel="noopener noreferrer"
                  padding="0 !important"
                  width="32px"
                >
                  <IconButton
                    aria-label="tiktok"
                    icon={<AiOutlineTikTok color={configFile.tertiaryAccentColor} width="12px" />}
                    bgColor={configFile.secondaryAccentColor}
                    size="sm"
                  />
                </ChakraLink>
              </Flex>
            </Box>
            {isMobileScreen && (
              <Accordion allowToggle w="100%">
                <AccordionItem border="none">
                  <AccordionButton
                    _expanded={{ bg: 'transparent' }}
                    p="10px 16px"
                    w="100%"
                    border="1px solid #FFF"
                    borderRadius="6px"
                    color={configFile.tertiaryAccentColor}
                    display="flex"
                    justifyContent="center"
                    gap="8px"
                    onClick={() => toggleAccordion(1)}
                  >
                    Exibir informações
                    {expandedIndex === 1 ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </AccordionButton>
                  <AccordionPanel>
                    <Flex
                      align="flex-start"
                      columnGap="100px"
                      as="nav"
                      display="flex"
                      flexDir={{ base: 'column', md: 'row' }}
                      rowGap="40px"
                    >
                      <Flex flexDirection="column" mt="32px">
                        <Stack key="menu-1" minW="176px">
                          <Flex direction="column" gap="24px">
                            <Heading as="h2" color="teal.500" fontWeight={600} fontSize="16px">
                              {menu[0].title}
                            </Heading>
                            {menu[0].items.map((item: FooterSubMenu) => (
                              <ChakraLink
                                id={ITEM_PATH_TO_ID[item.path]}
                                onClick={handleGTMClick}
                                onAuxClick={handleGTMClick}
                                href={item.path}
                                key={item.id}
                                fontWeight="400"
                                fontSize="14px"
                                display="block"
                                lineHeight="20px"
                                color={configFile.tertiaryAccentColor}
                                _hover={{
                                  color: configFile.fourthAccentColor,
                                }}
                                as="h3"
                              >
                                {item.title}
                              </ChakraLink>
                            ))}
                          </Flex>
                        </Stack>
                        <Stack key="menu-2" minW="176px" mt="24px">
                          <Flex direction="column" gap="24px">
                            <Heading as="h2" color="teal.500" fontWeight={600} fontSize="16px">
                              {menu[1].title}
                            </Heading>
                            {menu[1].items.map((item: FooterSubMenu) => (
                              <ChakraLink
                                id={ITEM_PATH_TO_ID[item.path]}
                                onClick={handleGTMClick}
                                onAuxClick={handleGTMClick}
                                href={item.path}
                                key={item.id}
                                fontWeight="400"
                                fontSize="14px"
                                display="block"
                                lineHeight="20px"
                                color={configFile.tertiaryAccentColor}
                                _hover={{
                                  color: configFile.fourthAccentColor,
                                }}
                                as="h3"
                              >
                                {item.title}
                              </ChakraLink>
                            ))}
                          </Flex>
                        </Stack>
                      </Flex>
                      {menu.slice(2).map((item: FooterMenuData) => (
                        <Stack key={item.id} minW="176px">
                          <Flex direction="column" gap="24px">
                            <Heading as="h2" color="teal.500" fontWeight={600} fontSize="16px">
                              {item.title}
                            </Heading>
                            {item.items.map((i: FooterSubMenu) => (
                              <ChakraLink
                                id={ITEM_PATH_TO_ID[i.path]}
                                onClick={handleGTMClick}
                                onAuxClick={handleGTMClick}
                                href={i.path}
                                key={i.id}
                                fontWeight="400"
                                fontSize="14px"
                                display="block"
                                lineHeight="20px"
                                color={configFile.tertiaryAccentColor}
                                _hover={{
                                  color: configFile.fourthAccentColor,
                                }}
                                as="h3"
                              >
                                {i.title}
                              </ChakraLink>
                            ))}
                          </Flex>
                        </Stack>
                      ))}
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
            {!isMobileScreen && (
              <Flex
                align="flex-start"
                columnGap="100px"
                as="nav"
                display="flex"
                flexDir={{ base: 'column', md: 'row' }}
              >
                <Flex direction="column">
                  {menu.slice(0, 2).map((item: FooterMenuData, index: number) => (
                    <Stack key={item.id} minW="176px" mb={index === 0 ? '20px' : '0'}>
                      <Flex direction="column" gap="12px">
                        <Text as="span" color="teal.500" fontWeight={600} fontSize="16px" mb="12px">
                          {item.title}
                        </Text>
                        {item.items.map((i: FooterSubMenu) => (
                          <ChakraLink
                            id={ITEM_PATH_TO_ID[i.path]}
                            onClick={handleGTMClick}
                            onAuxClick={handleGTMClick}
                            href={i.path}
                            key={i.id}
                            display="block"
                            as={Link}
                            p="0 !important"
                            target={i.path.includes('http') ? '_blank' : ''}
                          >
                            <Text
                              as="h3"
                              fontWeight="400"
                              fontSize="14px"
                              lineHeight="20px"
                              color={configFile.tertiaryAccentColor}
                              _hover={{ color: configFile.fourthAccentColor }}
                            >
                              {i.title}
                            </Text>
                          </ChakraLink>
                        ))}
                      </Flex>
                    </Stack>
                  ))}
                </Flex>
                {menu.slice(2, 3).map((item: FooterMenuData) => (
                  <Stack key={item.id} minW="176px">
                    <Flex direction="column" gap="12px">
                      <Heading as="h2" color="teal.500" fontWeight={600} fontSize="16px" mb="12px">
                        {item.title}
                      </Heading>
                      {item.items.map((i: FooterSubMenu) => (
                        <ChakraLink
                          id={ITEM_PATH_TO_ID[i.path]}
                          onClick={handleGTMClick}
                          onAuxClick={handleGTMClick}
                          href={i.path}
                          key={i.id}
                          display="block"
                          as={Link}
                          p="0 !important"
                        >
                          <Text
                            as="h3"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="20px"
                            color={configFile.tertiaryAccentColor}
                            _hover={{ color: configFile.fourthAccentColor }}
                          >
                            {i.title}
                          </Text>
                        </ChakraLink>
                      ))}
                    </Flex>
                  </Stack>
                ))}
                <Flex direction="column">
                  {menu.slice(3, 5).map((item: FooterMenuData, index: number) => (
                    <Stack key={item.id} minW="176px" mb={index === 0 ? '60px' : '0'}>
                      <Flex direction="column" gap="12px">
                        <Heading as="h2" color="teal.500" fontWeight={600} fontSize="16px" mb="12px">
                          {item.title}
                        </Heading>
                        {item.items.map((i: FooterSubMenu) => (
                          <ChakraLink
                            id={ITEM_PATH_TO_ID[i.path]}
                            onClick={handleGTMClick}
                            onAuxClick={handleGTMClick}
                            href={i.path}
                            key={i.id}
                            display="block"
                            as={Link}
                            p="0 !important"
                          >
                            <Text
                              as="h3"
                              fontWeight="400"
                              fontSize="14px"
                              lineHeight="20px"
                              color={configFile.tertiaryAccentColor}
                              _hover={{ color: configFile.fourthAccentColor }}
                            >
                              {i.title}
                            </Text>
                          </ChakraLink>
                        ))}
                      </Flex>
                    </Stack>
                  ))}
                </Flex>
              </Flex>
            )}
          </Flex>
          <Flex justifyContent="space-between" mb="80px" direction={{ base: 'column', lg: 'row' }} alignItems="center">
            <Text mb="32px" fontSize="14px" lineHeight="20px" color={configFile.tertiaryAccentColor} m="auto 0">
              Copyright {new Date().getFullYear()}. {t('footer.rights')}
            </Text>
            <Button
              id="footer_back_to_top"
              onClick={(e) => handleGTMClick(e, handleClickTop)}
              aria-label="go to top"
              size="lg"
              width="197px"
              bgColor={configFile.fourthAccentColor}
              color={configFile.tertiaryAccentColor}
              mt={{ base: '24px', lg: '0' }}
              _hover={{ bgColor: configFile.fiveAccentColor }}
            >
              <Flex flexDirection="row" w="100%" justifyContent="space-between">
                <ChevronUpIcon />
                {t('footer.footerButton')}
              </Flex>
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}
